import { useSelector } from 'react-redux'
import { RootState } from '@/store/redux/store'
import {
    fetchCaseHistoryThunk,
    // fetchFulfilledOrdersThunk,
    fetchOngoingOrdersThunk,
} from '../thunks/order.thunk'
import { createQueryString, debounce } from '@/utils'
import { useAppDispatch } from '@/store/redux/hooks'
import {
    AddNewOngoingCase,
    updateSearchKeyword,
    updateSelectedDateRange,
    updateSelectedOrderId,
    updateSelectedSource,
    updateTripTrackingData,
    updateOrderStatus,
    moveCaseToFulfilled,
    updateRefetchSummaryFlag,
} from '@/store/redux/orders/orderSlice'
import { useEffect } from 'react'
import { start } from 'repl'
import { updateSelectedClientId } from '@/store/redux/user/userSlice'
import useUser from '@/api/hooks/useUser'
import toast from 'react-hot-toast'
import { ONGOING_CASE_DURATION } from '@/config/constant'
import useQueryParams from '@/lib/useQueryParams'

const useOrders = () => {
    const { updateQueryParams, replacePathQuery, removeQueryParams } = useQueryParams()
    const dispatch = useAppDispatch()
    const dispatch2 = useAppDispatch()
    const { status, errors, fulfilledCases, ongoingCases, selectedOrderId, caseHistory, caseHistoryFilter, refetchSummary } = useSelector((state: RootState) => state.orderStore)
    const { selectedClientId } = useUser()

    const currentTimestamp = new Date().getTime()
    // TODO: change this for days wise
    const startTimestamp = currentTimestamp - 24 * ONGOING_CASE_DURATION * 60 * 60 * 1000

    const fetchOngoingCases = () => {
        // TODO: need to pick it from store
        const payload = {
            startDate: startTimestamp,
            endDate: currentTimestamp,
            sortType: 'desc',
            page: 0,
            limit: 1000,
        }
        const queryString = createQueryString(payload)

        if (selectedClientId) {
            dispatch(fetchOngoingOrdersThunk({ clientId: selectedClientId, query: queryString }))
        }
    }

    // useEffect(() => {
    // if(selectedClientId) {
    //   fetchOngoingCases()
    // }
    // },[selectedClientId])

    const getCaseHistory = (customPayload?: { from?: any; to?: any; source?: string }) => {
        const payload = {
            startDate: caseHistoryFilter?.dateRange?.from || startTimestamp,
            endDate: caseHistoryFilter?.dateRange?.to || currentTimestamp,
            // clientId: caseHistoryFilter?.selectedSource,
            sortType: 'desc',
            // size: 100,
            limit: 1000,
            page: 0,
        }

        if (customPayload?.from && customPayload?.to) {
            const convertedStartDate = new Date(customPayload.from).getTime()
            const convertedEndDate = new Date(customPayload.to).getTime()

            payload.startDate = convertedStartDate
            payload.endDate = convertedEndDate
        }

        const queryString = createQueryString(payload)
        const clientId = customPayload?.source || selectedClientId

        if (clientId) {
            dispatch(
                fetchCaseHistoryThunk({
                    clientId: clientId,
                    query: queryString,
                })
            )
        }
    }

    const AddNewOngoingCaseForSource = (payload: any) => {
        dispatch(AddNewOngoingCase(payload))
        console.log('new Case ---> ', payload)
    }

    const updateTripTrackingForOrder = (payload: any) => {
        dispatch(updateTripTrackingData(payload))
    }

    const updateOverallOrderStatus = (payload: any) => {
        dispatch(updateOrderStatus(payload))
    }

    const updateSummaryRefetchFlag = (payload: any) => {
        dispatch(updateRefetchSummaryFlag(payload))
    }

    const updateSelectedView = (orderId?: string) => {
        // setSelectedOrderId(orderId)
        updateQueryParams({ orderId: orderId })
        dispatch(updateSelectedOrderId(orderId || ''))
    }

    const updateStartAndEndDate = ({ from, to }: any) => {
        const startDateEpoch = new Date(from).getTime()
        const endDateEpoch = new Date(to).getTime()

        const payload = {
            from: startDateEpoch,
            to: endDateEpoch,
        }
        dispatch(updateSelectedDateRange(payload))
    }

    const moveCompletedCase = (payload: any) => {
        dispatch(moveCaseToFulfilled(payload))
    }

    const mountFromUrl = (source?: string, orderId?: string) => {
        const payload = {}
        if (orderId) {
            payload['orderId'] = orderId
            dispatch2(updateSelectedOrderId(orderId))
        }
        if (source) {
            payload['source'] = source
            dispatch(updateSelectedClientId(source))
        }
        if (Object.keys(payload).length) {
            updateQueryParams(payload)
        }
    }

    const updateGlobalSource = (source: string) => {
        replacePathQuery({ source: source })
        dispatch2(updateSelectedOrderId(''))
        dispatch(updateSelectedClientId(source))
    }

    const updateSource = (source: string) => {
        updateQueryParams({ source: source })
        getCaseHistory({ source })
    }

    const reff = (searchKeyword: string) => {
        dispatch(updateSearchKeyword(searchKeyword))
    }

    const updateSearchKeywordDebounce = debounce(reff, 300)

    return {
        status,
        errors,
        fulfilledCases,
        ongoingCases,
        refetchSummary,
        updateSummaryRefetchFlag,
        updateSource,
        updateGlobalSource,
        // fetchFulfilledCases,
        updateSearchKeywordDebounce,
        AddNewOngoingCaseForSource,
        caseHistory,
        caseHistoryFilter,
        getCaseHistory,
        fetchOngoingCases,
        updateOverallOrderStatus,
        selectedOrderId,
        updateSelectedView,
        updateStartAndEndDate,
        updateTripTrackingForOrder,
        moveCompletedCase,
        mountFromUrl,
    }
}

export default useOrders

// export const useCaseSearch = () => {
//     const { getCaseHistory, ...rest } = useOrders()

//     useEffect(() => {
//         getCaseHistory()
//     }, [])

//     return { ...rest }
// }
